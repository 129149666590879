import {colors} from "../primitives/colors";
import React from "react";
import {
    ProductRenderDisplayVariant,
} from "../../importer/customizer/utils/get-variants-for-display";
import MockedAsset from "./mocked-asset";
import {useGridContext} from "../primitives/grid/context";
import {Link} from "../../../plugins/gatsby-plugin-atollon";


interface SwatchProps {
    breakpoints?: {
        [key: string]: { itemsAtSize: number }
    }
    variants: ProductRenderDisplayVariant[],
    colorIndex: number,
    setColorIndex: React.Dispatch<React.SetStateAction<number>>,
    onImage?: boolean
    link: string
}


const InnerSwatch = ({ variants: productColors, colorIndex, setColorIndex, onImage, breakpoint, itemsAtSize, link
}: Omit<SwatchProps, "breakpoints"> & {
    breakpoint: string,
    itemsAtSize: number
}) =>  {

    const { sortedBreakpoints, maxWidth } = useGridContext();

    const matchedBp = sortedBreakpoints.find(x => x[0] === breakpoint)
    const trueMax = maxWidth + 28 + 28 + 56

    if (!matchedBp) return null;

    let digit = parseFloat(matchedBp[1].query.replace(/\D/g, ''))
    if (Number.isNaN(digit)) {
        digit = trueMax
    }

    const pixelsTotalAllowedPerItemAtBreakpoint = digit / (itemsAtSize);

    const sizeBps = productColors.reduce((acc, val, idx) => {

        const allowedPixels = Math.min(
            (idx + 1) * pixelsTotalAllowedPerItemAtBreakpoint + pixelsTotalAllowedPerItemAtBreakpoint,
            pixelsTotalAllowedPerItemAtBreakpoint * itemsAtSize
        );

        const query = `@media (min-width: ${allowedPixels}px)`

        if (!acc['@media screen']) {
            acc['@media screen'] = {}
        }
        productColors.forEach((color, innerIdx) => {
            acc['@media screen'][`&:nth-of-type(n+${innerIdx+1})`] = {
                display: (() => {
                    if (innerIdx > (itemsAtSize + 2)) {
                        return 'none'
                    }
                    return 'block'
                })()
            }
        })

        if (!acc[query]) {
            acc[query] = {}
        }

        productColors.forEach((color, innerIdx) => {
            const requiredPixels = (innerIdx + 1) * pixelsTotalAllowedPerItemAtBreakpoint;

            acc[query][`&:nth-of-type(n+${innerIdx + 1})`] = {
                display: (() => {
                    if (itemsAtSize < innerIdx) {
                        return 'none'
                    }

                    const condition = innerIdx === productColors.length - 1 ? requiredPixels <= (allowedPixels) : requiredPixels < (allowedPixels)

                    return condition ? 'block' : 'none'
                })()
            }
        })
        return acc;
    }, {})

    const afterBps = productColors.reduce((acc, val, idx) => {

        const allowedPixels = Math.min(
            (idx + 1) * pixelsTotalAllowedPerItemAtBreakpoint + pixelsTotalAllowedPerItemAtBreakpoint,
            pixelsTotalAllowedPerItemAtBreakpoint * itemsAtSize
        );

        const allowedItemsAtSize = allowedPixels / pixelsTotalAllowedPerItemAtBreakpoint

        const query = `@media (min-width: ${allowedPixels}px)`

        const shownColorCount = productColors.length - allowedItemsAtSize

        acc[query] = {
            content: `'+${Math.round(shownColorCount) + 1}'`,
            display: shownColorCount < 1 ? 'none' : 'flex'
        }

        return acc;
    }, {})

    return (
        <Link key={link} to={link} css={{
            display: 'flex',
            gap: '3px',
            padding: '8px',
            backgroundColor: colors.shadesWhite,
            boxShadow: onImage ? '2px 4px 9px #0002' : undefined,
            borderRadius: '500px',
            width: 'fit-content',
            zIndex: 1,
            position: 'relative',
            '&:after': {
                content: "''",
                width: '32px',
                height: '32px',
                borderRadius: '500px',
                border: `1px solid ${colors.neutral10}`,
                color: colors.neutral10,
                fontSize: '12px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...afterBps
            }
        }}>
            {productColors.map((color, idx) => (
                <div role="button" css={{
                    display: 'flex',
                    width: '32px',
                    height: '32px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '500px',
                    ...sizeBps,
                }} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setColorIndex(idx)
                }} style={{
                    border: idx === colorIndex ?  `1px solid ${colors.primaryOrange}` : `1px solid ${colors.neutral4}`,
                }}>
                    <MockedAsset css={{
                        border: `1px solid ${colors.shadesWhite}`,
                        padding: '1px',
                        width: '30px',
                        height: '30px',
                        transition: 'border 0.2s',
                        borderRadius: '500px',
                        display: 'block',
                        overflow: 'hidden',
                        transform: 'translateZ(0px)'
                    }} asset={color.image} width={36} />
                </div>
            ))}
        </Link>
    )
}

const Swatch = ({ breakpoints = {
    dt: { itemsAtSize: 8 },
    tb: { itemsAtSize: 12 },
    mb: { itemsAtSize: 14 }
}, ...props }: SwatchProps) => {
    const { sortedBreakpoints } = useGridContext()

    const obj = sortedBreakpoints.map((x, idx) => {
        if (breakpoints[x[0]]) {
            const maybeNextBreakpoint = sortedBreakpoints[idx + 1]
            const styles = {
                display: 'none',
                [x[1].query]: {
                    display: 'block'
                }
            }

            if (maybeNextBreakpoint) {
                styles[maybeNextBreakpoint[1].query] = {
                    display: 'none'
                }
            }
            return {
                itemsAtSize: breakpoints[x[0]].itemsAtSize,
                styles,
                breakpointName: x[0]
            }
        }
    }).filter(x => !!x)

    return obj.map((v) => {
        if (v) {
            return (
                <span css={v.styles}>
                    <InnerSwatch {...props} breakpoint={v.breakpointName} itemsAtSize={v.itemsAtSize} />
                </span>
            )
        }
        return null
    })
}

export default Swatch
